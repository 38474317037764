// @ts-nocheck

import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  useMemo,
  Reducer,
  ReducerAction,
  Dispatch,
  SetStateAction
} from 'react'
import { Uneeq } from 'uneeq-js'
import { setEventHandler, trackUneeqMessage } from '../analytics'
import { useSupportedBrowser } from '../hooks'
import testState from '../utils/testState'
import defaultConfig from './defaultConfig'
import legacyReducer from './state/legacyReducer'
import initialState, {
  closeDialogs,
  UneeqCoreConfig
} from './state/initialState'
import UneeqContext from './UneeqContext'
import usePreApprove from './usePreApprove'
import useTimeoutUpdate from './useTimeoutUpdate'
import useSpacebarToTalk from './useSpacebarToTalk'
import { AnyUneeqMessage, UneeqState, Config } from '../uneeq'
import runReducers from './state/runReducers'
import * as countries from 'i18n-iso-countries'
import runMiddleware from './state/runMiddleware'
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

interface UneeqProviderProps {
  children: React.ReactNode
  onSessionEnded: () => void
  onTimedOut: () => void
  postInit?: (uneeq: Uneeq) => void
  speak?: boolean
  config: Partial<UneeqCoreConfig>
  restart?: () => void
  reducers: Reducer<any, any>[]
  middleware?: any[]
  token?: string
  getToken?: () => Promise<{ token: string; session: string }>
  fullscreen?: boolean
  setFullscreen?: Dispatch<SetStateAction<boolean>>
}

const processAction = (action: ReducerAction<Reducer<any, any>>) => {
  // TODO move this logic to handleUneeqMessage when legacy reducer is gone or can handle this.
  if (action.type === 'uneeqMessage') {
    const { uneeqMessageType, ...payload } = action.payload
    if (uneeqMessageType === 'AvatarAnswer') {
      const answer = JSON.parse(payload.answerAvatar)
      if (answer.instructions.displayHtml?.html) {
        try {
          const command = JSON.parse(answer.instructions.displayHtml.html)
          // UneeQ Command
          return {
            type: 'uneeqCommand',
            payload: command
          }
        } catch (e) {
          console.error('unexpected answer.instructions:', answer.instructions)
          console.error(e)
        }
      }
    }
    // UneeQ Message
    return {
      type: 'uneeqMessage' + uneeqMessageType,
      payload
    }
  }
  // Front-end Action
  return action
}

const UneeqProvider: React.FC<UneeqProviderProps> = ({
  children,
  onSessionEnded,
  postInit,
  onTimedOut,
  speak,
  config,
  restart,
  reducers,
  middleware = [],
  token,
  getToken,
  fullscreen,
  setFullscreen
}) => {
  const uneeq: any = useRef() // UneeQ Instance

  const { isGteIOS13, isMobileSafari } = useSupportedBrowser()

  const finalConfig = useMemo(
    () =>
      ({
        ...defaultConfig,
        ...config,
        sendLocalVideo: config.sendLocalVideo
      } as Config),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, isMobileSafari, isGteIOS13]
  )

  // Elements
  const [avatarVideo, setAvatarVideo] = useState<HTMLDivElement>()
  const [localVideo, setLocalVideo] = useState<HTMLDivElement>()

  const reducer = useCallback(
    (state: UneeqState, action: any) => {
      const processedAction = processAction(action)
      runMiddleware(middleware, state, processedAction, uneeq.current, context)
      return runReducers(
        [
          ...reducers,
          // Legacy Reducer - note: uses action from closure, not passed in
          state => legacyReducer(state, action, finalConfig)
        ],
        state,
        processedAction
      )
    },
    [context, finalConfig, middleware, reducers]
  )

  const [state, dispatch] = useReducer(reducer, initialState(finalConfig))

  const handleUneeqMessage = (message: AnyUneeqMessage) => {
    dispatch({ type: 'uneeqMessage', payload: message })
    trackUneeqMessage(message)
  }

  // Manage permissions approval process (unless using a testState)
  usePreApprove(testState ? () => {} : dispatch, finalConfig)

  useEffect(() => {
    if (finalConfig.analytics) {
      setEventHandler(finalConfig.analytics)
    }
  }, [finalConfig.analytics])

  // put handleUneeqMessage into a ref so we can turn it into a noop prevent UneeQ-js from calling it after unmount
  const messageHandler = useRef(handleUneeqMessage)
  // init
  useEffect(() => {
    // true for quicker testing - skip connecting to backend and show UI
    if (testState) {
      uneeq.current = {
        sendTranscript: (t: string) =>
          console.info('sendTranscript called:', t),
        startRecording: () => console.info('startRecording called'),
        stopRecording: () => console.info('stopRecording  called'),
        endSession: () => console.warn('endSession called')
      }
      if (testState.ready !== false && !('permissionAllowed' in testState)) {
        dispatch({
          type: 'uneeqMessage',
          payload: {
            uneeqMessageType: 'DevicePermissionAllowed'
          }
        })
      }
      if (testState.ready !== false && !testState.unavailable) {
        dispatch({
          type: 'uneeqMessage',
          payload: {
            uneeqMessageType: 'AvatarAvailable'
          }
        })
        dispatch({
          type: 'uneeqMessage',
          payload: {
            uneeqMessageType: 'SessionLive'
          }
        })
      }
      return
    }
    // when both elements are available
    if (avatarVideo && speak) {
      uneeq.current = new Uneeq({
        playWelcome: finalConfig.playWelcome,
        url: finalConfig.url,
        conversationId: finalConfig.conversationId,
        messageHandler: msg => messageHandler.current(msg),
        avatarVideoContainerElement: avatarVideo as HTMLDivElement,
        localVideoContainerElement: localVideo as HTMLDivElement,
        sendLocalVideo: finalConfig.sendLocalVideo,
        sendLocalAudio: finalConfig.sendLocalAudio,
        customData: finalConfig.customData,
        preferredCameraId: localStorage.getItem('videoInput') || undefined,
        preferredMicrophoneId: localStorage.getItem('audioInput') || undefined,
        preferredSpeakerId: localStorage.getItem('audioOutput') || undefined
      })

      let finalDisableDH = !speak
      // Fetch token
      if (token) {
        uneeq.current.initWithToken(token)
      } else if (getToken) {
        getToken().then(
          ({ disableDigitalHuman, hideQuestion, token, title }: any) => {
            if (hideQuestion || disableDigitalHuman) {
              disableDigitalHuman = false // TODO REMOVE!!!!!!! overrides what comes from init for testing purposes
              finalDisableDH = disableDigitalHuman || finalDisableDH
              dispatch({
                type: 'setConfig',
                payload: {
                  token,
                  title,
                  hideQuestion,
                  disableDigitalHuman: finalDisableDH
                }
              })
            }
            return !finalDisableDH && uneeq.current.initWithToken(token)
          }
        )
      } else {
        fetch(finalConfig.tokenUrl, {
          headers: finalConfig.orchestrationToken
            ? {
                Authorization: `Bearer ${finalConfig.orchestrationToken}`
              }
            : {}
        })
          .then(response => {
            if (response.status === 200) {
              return response.json()
            } else {
              return Promise.reject(new Error(response.statusText))
            }
          })
          .then(response => {
            const { token } = response
            uneeq.current.initWithToken(token)
          })
          .catch(error => {
            dispatch({ type: 'tokenError', message: error.message })
          })
      }
      if (postInit) postInit(uneeq.current)

      // Return cleanup
      return () => {
        // make the handler a noop so dispatch is not called after unmount
        messageHandler.current = () => {}
      }
    }
  }, [
    avatarVideo,
    finalConfig.tokenUrl,
    finalConfig.orchestrationToken,
    finalConfig.conversationId,
    finalConfig.customData,
    finalConfig.playWelcome,
    finalConfig.sendLocalVideo,
    finalConfig.url,
    isGteIOS13,
    isMobileSafari,
    localVideo,
    postInit,
    token,
    finalConfig.sendLocalAudio,
    speak,
    getToken
  ])

  const endSession = () => {
    if (uneeq && uneeq.current) {
      uneeq.current.endSession()
    }
  }

  const restartSession = () => {
    restart && restart()
    endSession()
  }

  const { sessionEnded, timedOut } = state

  useEffect(() => {
    if (timedOut) onTimedOut()
    if (sessionEnded) onSessionEnded()
  }, [onTimedOut, timedOut, onSessionEnded, sessionEnded])

  const speakTranscript = (transcript: any) => {
    // Sanitise transcript before speaking
    const doc = new DOMParser().parseFromString(transcript, 'text/html')
    const sanitisedTranscript = doc.body.textContent || ''
    console.log('Speaking sanitised transcript: ', sanitisedTranscript)

    const url = `${process.env.REACT_APP_UNEEQ_SPEAK_URL}/${
      uneeq.current.sessionId
    }/${encodeURIComponent(transcript)}`
    fetch(url)
      .then(response => {
        if (response.status === 200) {
          return
        } else {
          return Promise.reject(new Error(response.statusText))
        }
      })
      .catch(error => {
        dispatch({ type: 'speakError', message: error.error })
      })
  }

  const sendText = (text: string) => {
    // @ts-ignore - https://github.com/microsoft/TypeScript/issues/33736
    if (state.onScreenInfo?.suggestedResponses?.questionId) {
      const data = JSON.stringify({
        // @ts-ignore
        questionId: state.onScreenInfo?.suggestedResponses?.questionId,
        response: text
      })
      dispatch({ type: 'sendQuestionResponse' })
      !state.sessionPaused && uneeq.current.sendTranscript(data)
    } else {
      !state.sessionPaused && uneeq.current.sendTranscript(text)
    }
  }
  const sendData = (data: any) => sendText(JSON.stringify(data))
  const startRecording = () => uneeq.current?.startRecording()
  const stopRecording = () => uneeq.current?.stopRecording()

  // dispatch `timeoutUpdate` actions as needed to keep the state updated
  const { resetTimeout } = useTimeoutUpdate(
    state,
    dispatch,
    onTimedOut,
    finalConfig.timeoutWarning
  )

  const setDevice = useCallback((deviceType, deviceId) => {
    localStorage.setItem(deviceType, deviceId)
    switch (deviceType) {
      case 'videoInput':
        uneeq.current.setCamera(deviceId)
        break
      case 'audioInput':
        uneeq.current.setMic(deviceId)
        break
      case 'audioOutput':
        uneeq.current.setSpeaker(deviceId)
        break
      default:
        console.error('unrecognised device type:', deviceType)
        break
    }
  }, [])

  const volume = {
    watch: (listener: any) => {
      const setter = () =>
        listener((avatarVideo?.children[0] as HTMLVideoElement)?.volume)
      avatarVideo?.children[0].addEventListener('volumechange', setter)
      // call now to set initial value
      setter()

      // cleanup function
      return () =>
        avatarVideo?.children[0].removeEventListener('volumechange', setter)
    },
    set: (level: number) => {
      if (avatarVideo) {
        ;(avatarVideo.children[0] as HTMLVideoElement).volume = level
      }
    }
  }

  const playDHVideo = () => {
    const dhVideo = avatarVideo?.children[0] as HTMLVideoElement
    if (dhVideo?.paused) {
      dhVideo.play()
    }
  }

  const updateAvatarPosition = () => {
    // Trigger this scroll will cause the UneeqAvatar useDimensions hooks to re-update the UI
    window.scrollTo({ top: 1 })
  }

  const repeatLastQuestion = () => {
    updateAvatarPosition()
    if (state.mayaQuestion && !state.sessionPaused) {
      const speak = state.mayaQuestion.speak || state.mayaQuestion.question
      uneeq.current.sendTranscript(speak)
    }
  }

  const allDialogsClosed = () => {
    return Object.keys(closeDialogs).every(
      key =>
        state[key as keyof typeof closeDialogs] ===
        closeDialogs[key as keyof typeof closeDialogs]
    )
  }

  const finalCountries = useMemo(() => {
    const countriesObj = countries.getNames('en', { select: 'official' })
    return Object.entries(countriesObj).sort(([codeA, nameA], [codeB, nameB]) =>
      nameA.toUpperCase().localeCompare(nameB.toUpperCase())
    )
  }, [])

  const context = {
    dispatch,
    setAvatarVideo,
    avatarVideo,
    setLocalVideo,
    localVideo,
    speakTranscript,
    sendText,
    sendData,
    setDevice,
    endSession,
    restartSession,
    resetTimeout,
    volume,
    startRecording,
    stopRecording,
    config: finalConfig,
    state,
    sessionId: uneeq.current?.sessionId,
    countries: finalCountries,
    allDialogsClosed,
    hideModal: () => dispatch({ type: 'closeModal' }),
    testMessage: (message: string) =>
      dispatch({ type: 'uneeqMessage', payload: message }),
    playDHVideo,
    repeatLastQuestion,
    fullscreen,
    setFullscreen
  }

  return (
    <UneeqContext.Provider value={context}>{children}</UneeqContext.Provider>
  )
}

export default UneeqProvider
