import React, { useContext } from 'react'
import { Flex, Button, Box } from 'rebass'
import { useUneeqState, UneeqContext } from 'uneeq-react-core'
import styles from './styles'
import TextQuestion from './questionTypes/TextQuestion'
import SelectQuestion from './questionTypes/SelectQuestion'
import MultiSelectQuestion from './questionTypes/MultiSelectQuestion'
import CountryQuestion from './questionTypes/CountryQuestion'
import AutocompleteQuestion from './questionTypes/AutocompleteQuestion'
import TextareaQuestion from './questionTypes/TextareaQuestion'
import MayaBackButton from '../MayaBackButton/MayaBackButton'
import MayaHomeButton from '../MayaHomeButton/MayaHomeButton'
import TranscriptButton from '../MayaTranscript/TranscriptButton'
import DateQuestion from './questionTypes/DateQuestion'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { motion } from 'framer-motion'
import MayaFullscreenButton from '../MayaFullScreenButton/MayaFullscreenButton'
import useWidgetContext from '../../app/hooks/useWidgetContext'
import { isMobileOnly } from 'react-device-detect'
import RestartSession from '../RestartSession/RestartSession'
interface QuestionsProps {
  mayaQuestionFormat:
    | 'text'
    | 'number'
    | 'textarea'
    | 'date'
    | 'select'
    | 'multiSelect'
    | 'country'
    | 'autocomplete'
    | 'email'
    | 'phone'
}

const LoadingIndicator = React.memo(() => {
  const MotionContainer = motion.custom(Flex)

  return (
    <MotionContainer
      variants={{
        initial: {
          opacity: 0,
          transition: {
            delay: 3
          }
        },
        animate: {
          opacity: 1
        },
        exit: {
          opacity: 0,
          transition: {
            delay: 3
          }
        }
      }}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        bg: 'backgroundOverlay',
        zIndex: 5,
        borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
        backdropFilter: 'blur(1px)'
      }}
    >
      <LoadingSpinner />
    </MotionContainer>
  )
})

interface AnimatedBoxProps {
  scrollable?: boolean
  children: React.ReactNode
}

const AnimatedBox = ({ children, scrollable = true }: AnimatedBoxProps) => {
  const MotionContainer = motion.custom(Box)
  const animation = {
    initial: {
      opacity: 0,
      transition: {
        delay: 3
      }
    },
    animate: {
      opacity: 1
    },
    exit: {
      opacity: 0,
      transition: {
        delay: 3
      }
    }
  }

  return (
    <MotionContainer
      variants={animation}
      initial="initial"
      animate="animate"
      exit="exit"
      type="text"
      sx={{ overflowY: scrollable ? 'auto' : 'initial' }}
    >
      {children}
    </MotionContainer>
  )
}

const Questions = React.memo(({ mayaQuestionFormat }: QuestionsProps) => {
  switch (mayaQuestionFormat) {
    case 'text':
      return (
        <AnimatedBox>
          <TextQuestion type="text" />
        </AnimatedBox>
      )
    case 'number':
      return (
        <AnimatedBox>
          <TextQuestion type="number" />
        </AnimatedBox>
      )
    case 'email':
      return (
        <AnimatedBox>
          <TextQuestion type="email" />
        </AnimatedBox>
      )
    case 'phone':
      return (
        <AnimatedBox>
          <TextQuestion type="tel" />
        </AnimatedBox>
      )
    case 'textarea':
      return (
        <AnimatedBox>
          <TextareaQuestion />
        </AnimatedBox>
      )
    case 'date':
      return (
        <AnimatedBox scrollable={false}>
          <DateQuestion />
        </AnimatedBox>
      )
    case 'select':
      return (
        <AnimatedBox>
          <SelectQuestion />
        </AnimatedBox>
      )
    case 'multiSelect':
      return (
        <AnimatedBox>
          <MultiSelectQuestion />
        </AnimatedBox>
      )
    case 'country':
      return (
        <AnimatedBox>
          <CountryQuestion />
        </AnimatedBox>
      )
    case 'autocomplete':
      return <AutocompleteQuestion />
    default:
      return null
  }
})

interface QuestionProps {
  speak?: boolean
}

const Question = ({ speak }: QuestionProps) => {
  const {
    mayaQuestion,
    sessionPaused,
    questionLoading,
    showMap,
    hideQuestion
  } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const { widgetMode } = useWidgetContext()

  if (!mayaQuestion || hideQuestion) return null
  const questionsContainerStyle =
    mayaQuestion.format === 'autocomplete'
      ? styles.questionAutocompleteContainer
      : styles.questionsContainer

  const toggleDigitalHuman = () => {
    sessionPaused ? resumeDigitalHuman() : pauseDigitalHuman()
  }

  const pauseDigitalHuman = () => {
    dispatch({ type: 'pauseSession' })
  }

  const resumeDigitalHuman = () => {
    dispatch({ type: 'resumeSession' })
  }

  if (showMap) return null

  return (
    <Flex sx={questionsContainerStyle}>
      {questionLoading && <LoadingIndicator />}
      <Questions mayaQuestionFormat={mayaQuestion.format} />
      <Flex sx={styles.questionsFooter}>
        {!mayaQuestion.hideBack ? <MayaBackButton /> : <div>&nbsp;</div>}
        <Flex sx={styles.questionsFooterActions}>
          {widgetMode && !isMobileOnly && <MayaFullscreenButton />}
          <TranscriptButton />
          {speak && (
            <Button
              id="dh-toggle"
              onClick={toggleDigitalHuman}
              variant="whiteInverted"
            >
              <span
                style={{
                  marginRight: '8px'
                }}
              >
                &bull;
              </span>{' '}
              {sessionPaused ? 'Maya' : 'Chat'}
            </Button>
          )}
          <RestartSession
            onClick={() => dispatch({ type: 'mayaOpenConfirmRestart' })}
          />
          <MayaHomeButton
            onClick={() => dispatch({ type: 'mayaOpenConfirmLeave' })}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default React.memo(Question)
